import gql from 'graphql-tag'

export const INVOICE_LIST = gql`
  query search_invoice (
    $search_query: String,
    $invoice_status: String
  ) {
    search_invoice (
      search_query: $search_query,
      invoice_status: $invoice_status
    ) {
      invoice_no
      invoice_status
      invoice_date
    }
  }
`

export const INVOICE_DETAIL = gql`
  query get_invoice ($invoice_no: String!) {
    get_invoice (invoice_no: $invoice_no) {
      invoice_no
      invoice_status
      invoice_date
      invoice_amount
      payment_date
      mail_invoice
      invoice_to {
        index
        name
        address
        email
        phone
      }
      invoice_file {
        S3_key
        file_name
      }
      submissions {
        BestLIMS_key
        submission_status
        submit_date
        submission_type
        submitter {
          name
        }
        sample_owner {
          name
        }
      }
    }
  }
`
